import { useLazyQuery } from '@apollo/client';
import { GETMETROUSAGEDAILY, GETMETROUSAGEHISTORY } from '_queries';
import { MetroUsageChartData } from '_types';
import { useAppSelector } from 'app/hooks';
import HorizontalBarGraph from 'components/Graphs/HorizontalBarGraph';
import { customTooltip, formatMetroUsageData } from 'components/util';
import { getMetroUsageData } from 'features/StationView/DashboardSlice';
import React, { useEffect, useState } from 'react';
import { useRoute } from 'wouter';

const MetroToEast: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [metroUsageData, setMetroUsageData] = useState<(string | number)[][]>([]);

    const [
        getMetroUsageHistoryData,
        { loading: metroUsageHistoryLoading, error: metroUsageHistoryError },
    ] = useLazyQuery<MetroUsageChartData>(GETMETROUSAGEHISTORY, {
        variables: { site_id: stationID },
    });

    const [
        getMetroUsageDailyData,
        { loading: metroUsageDailyLoading, error: metroUsageDailyError },
    ] = useLazyQuery<MetroUsageChartData>(GETMETROUSAGEDAILY, {
        variables: { site_id: stationID },
    });

    const metroFrequency = useAppSelector(getMetroUsageData);

    const loading = metroUsageHistoryLoading || metroUsageDailyLoading;
    const error = metroUsageHistoryError || metroUsageDailyError;

    useEffect(() => {
        if (metroFrequency?.east?.value === 'daily') {
            getMetroUsageDailyData()?.then((dailyData) => {
                const { data } = dailyData || {};

                const eastSideData = data?.getDailyMetroUsageChart?.filter(
                    (data) => data?.metro_side === 'east'
                );
                const dataSet = formatMetroUsageData(eastSideData);

                setMetroUsageData([...dataSet]);
            });
        }
        if (metroFrequency?.east?.value === 'history') {
            getMetroUsageHistoryData()?.then((historyData) => {
                const { data } = historyData || {};

                const eastSideData = data?.getMetroUsageChart?.filter(
                    (data) => data?.metro_side === 'east'
                );
                const dataSet = formatMetroUsageData(eastSideData);

                setMetroUsageData([...dataSet]);
            });
        }
    }, [metroFrequency]);

    return (
        <HorizontalBarGraph
            title="Metro to East"
            data={metroUsageData}
            dimensions={['car', 'Door 1', 'Door 2', 'Door 3']}
            seriesOption={{
                label: {
                    show: true,
                    position: 'right',
                    color: 'white',
                    fontSize: 10,
                },
            }}
            xAxisOption={{
                type: 'value',
                splitLine: {
                    show: false,
                },
            }}
            yAxisOption={{
                type: 'category',
                position: 'left',
                axisTick: {
                    alignWithLabel: true,
                },
                splitLine: {
                    show: false,
                },
            }}
            tooltipOption={{
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params) => {
                    const name = `Car ${params[0]?.name}`;
                    return customTooltip(params, name);
                },
            }}
            loading={loading}
            error={error}
        />
    );
};

export default MetroToEast;
