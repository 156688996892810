import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type Side = {
    value: string;
};
export type DashboardPanel = { east: Side; west: Side };

export interface DashboardSliceState {
    metroUsageFrequency: DashboardPanel;
}

const initialState: DashboardSliceState = {
    metroUsageFrequency: {
        east: {
            value: '',
        },
        west: {
            value: '',
        },
    },
};

/*===============================
              Slice
===============================*/

export const DashboardSlice = createSlice({
    name: 'dashboardData',
    initialState,
    reducers: {
        setMetroUsageFrequency: (state, action: PayloadAction<{ side: 'east' | 'west'; value: string }>) => {
            const { side, value } = action.payload;
            state.metroUsageFrequency[side].value = value;
        },
    },
});

/*===============================
            Actions
===============================*/

export const { setMetroUsageFrequency } = DashboardSlice.actions;

/*===============================
           Selectors
===============================*/

export const getMetroUsageData = (state: RootState) => state.dashboardSlice.metroUsageFrequency;

export default DashboardSlice.reducer;
