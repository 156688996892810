import { useQuery } from '@apollo/client';
import { GETINOUTCOUNTWEEKLY } from '_queries';
import { PeopleFlowChart, PeopleFlowData } from '_types';
import BarGraph from 'components/Graphs/BarGraph';
import { calculateDataZoomStart } from 'components/Graphs/graphUtils';
import { customTooltip } from 'components/util';
import React, { useEffect, useState } from 'react';
import { useRoute } from 'wouter';

const PeopleFlowTrend: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [inOutCount, setInOutCount] = useState<PeopleFlowData>([]);
    const { data, loading, error } = useQuery<PeopleFlowChart>(GETINOUTCOUNTWEEKLY, {
        variables: { site_id: stationID },
    });
    const { getInOutCountWeeklyChart } = data || {};
    const dataZoomStart = calculateDataZoomStart(inOutCount, 7);

    useEffect(() => {
        if (!getInOutCountWeeklyChart) return;

        setInOutCount([...getInOutCountWeeklyChart]);
    }, [getInOutCountWeeklyChart]);

    const transformData = inOutCount?.map((data) => ({
        inOutCount: parseInt(data?.in_counts) + parseInt(data?.out_counts),
        local_date: data?.local_date,
    }));

    return (
        <BarGraph
            title="People Flow trend"
            data={transformData}
            dimensions={['local_date', 'inOutCount']}
            xAxisOption={{
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    formatter: (param) => {
                        const date = new Date(param);

                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');

                        return `${month}-${day}`;
                    },
                },
            }}
            yAxisOption={{
                type: 'value',
            }}
            tooltipOption={{
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params) => {
                    return customTooltip(params, params[0]?.name);
                },
            }}
            graphOption={{
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        xAxisIndex: [0],
                        start: dataZoomStart,
                        end: 100,
                    },
                    {
                        type: 'inside',
                        xAxisIndex: [0],
                    },
                ],
            }}
            loading={loading}
            error={error}
        />
    );
};

export default PeopleFlowTrend;
