import React from 'react';
import { ReactComponent as RightDownArrow } from '../../assets/icons/arrow-rightdown.svg';
import { ReactComponent as RightUpArrow } from '../../assets/icons/arrow-rightup.svg';
import { useQuery, useSubscription } from '@apollo/client';
import {
    GETPLATFORMCOUNT,
    GETPLATFORMCOUNTLIVE,
    GETSTATIONINOUTCOUNT,
    SUBSCRIBESTATIONINOUTCOUNT,
} from '_queries';
import {
    PlatformPeopleCount,
    StationInOutCount,
    PlatformPeopleCountLive,
    StationInOutCountLive,
} from '_types/queries';
import { useRoute } from 'wouter';
import { useAppSelector } from 'app/hooks';
import { selectEye, selectTarget } from 'features/StationView/StationViewSlice';
import { SideBarAlerts } from './SideBarAlerts';

const LiveComponents: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [liveCount, setLiveCount] = React.useState<number>(0);

    // For dev use
    const getEye = useAppSelector(selectEye);
    const getTarget = useAppSelector(selectTarget);
    const [liveStationInOutCount, setLiveInOutCount] = React.useState({ incoming: 0, outgoing: 0 });

    /**
     * Fetch Data for in/out count of the station
     * @First query is used to fetch the in/out count of the station
     * @Second subscribe the in/out count of the station
     */
    useQuery<StationInOutCount>(GETSTATIONINOUTCOUNT, {
        variables: { site_id: stationID },
        onCompleted: (data) => {
            setLiveInOutCount({
                incoming: data.getStationInOutCount?.incoming ?? 0,
                outgoing: data.getStationInOutCount?.outgoing ?? 0,
            });
        },
    });
    const { data: liveInOutCount } = useSubscription<StationInOutCountLive>(
        SUBSCRIBESTATIONINOUTCOUNT,
        {
            variables: { site_id: stationID },
        }
    );
    React.useEffect(() => {
        if (liveInOutCount?.onStationInOutCount) {
            setLiveInOutCount({
                incoming: liveInOutCount.onStationInOutCount.incoming,
                outgoing: liveInOutCount.onStationInOutCount.outgoing,
            });
        }
    }, [liveInOutCount]);

    /**
     * Fetch Data for platform count of the station
     * @First query is used to fetch the platform count of the station
     * @Second subscribe the platform count of the station
     */
    useQuery<PlatformPeopleCount>(GETPLATFORMCOUNT, {
        variables: { site_id: stationID },
        onCompleted: (data) => {
            if (data.getPlatformCountLive) {
                setLiveCount(data.getPlatformCountLive.curr_count);
            }
        },
    });
    const { data: livePlatformCount } = useSubscription<PlatformPeopleCountLive>(
        GETPLATFORMCOUNTLIVE,
        {
            variables: { site_id: stationID },
        }
    );
    React.useEffect(() => {
        if (livePlatformCount?.onPlatformCount) {
            setLiveCount(livePlatformCount.onPlatformCount.curr_count);
        }
    }, [livePlatformCount]);

    return (
        <>
            <div className="live-stats stats-group">
                <div className="platform-stats stats-data">
                    <label htmlFor="platform-stats">Platform stats</label>
                    <div className="occupant-counts data-group station-overview-data-group">
                        <label htmlFor="occupant-counts">Total passengers</label>
                        <div className="station-overview-data">
                            <span className="incoming">
                                <RightDownArrow />
                                {liveStationInOutCount.incoming ?? '--'}
                            </span>
                            <span className="outgoing">
                                {liveStationInOutCount.outgoing ?? '--'}
                                <RightUpArrow />
                            </span>
                        </div>
                    </div>
                    <div className="data-group">
                        <div className="data">
                            <label>People on platform</label>
                            <span>{liveCount ?? '--'}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="live-alerts">
                <SideBarAlerts/>
            </div>
            {
                // window.location.pathname.includes('01JKD7GS1FX779DK4SY3PPYAEJ') && (
                window.location.hostname === 'localhost' && (
                    // || window.location.hostname.includes('dev')
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '16px',
                            border: '1px solid #ddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                            backgroundColor: '#fff',
                            maxWidth: '220px',
                            fontFamily: 'Arial, sans-serif',
                            marginTop: '20px',
                            marginLeft: '10px',
                        }}>
                        {[
                            { label: 'Target', data: getTarget, color: '#007bff' },
                            { label: 'Eye', data: getEye, color: '#28a745' },
                        ].map(({ label, data, color }) => (
                            <div key={label} style={{ textAlign: 'center' }}>
                                <div
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        color: '#333',
                                        marginBottom: '4px',
                                    }}>
                                    {label}:
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '8px',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        color,
                                    }}>
                                    <span>{data?.x !== undefined ? data.x.toFixed(2) : '--'}</span>
                                    <span>{data?.y !== undefined ? data.y.toFixed(2) : '--'}</span>
                                    <span>{data?.z !== undefined ? data.z.toFixed(2) : '--'}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </>
    );
};

export default LiveComponents;
