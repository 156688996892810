import { createSelector, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { EquipmentAlertQuery } from '_types/queries';
import { AlertsPanel, AlertsPanelWithId } from '_types';
import { VizStore } from 'components/Visualizer/Visualizer';

export interface AlertsSliceState {
    alerts: EquipmentAlertQuery[];
    toRead: boolean;
    alertsPanels: Record<string, AlertsPanel>;
}

const initialState: AlertsSliceState = {
    alerts: [],
    toRead: false,
    alertsPanels: {},
};

/*===============================
              Slice
===============================*/

export const SimulationViewSlice = createSlice({
    name: 'alertsView',
    initialState,
    reducers: {
        // get all alerts
        setAlerts: (state, action: PayloadAction<EquipmentAlertQuery[]>) => {
            state.alerts = state.alerts.concat(action.payload);
            state.toRead = !!action.payload.find((alert) => alert.read_status === 'unread');
        },
        setAlertToRead: (state, action: PayloadAction<string>) => {
            const index = state.alerts.findIndex((alert) => alert.timestamp === action.payload);
            if (index !== -1) {
                state.alerts = [
                    ...state.alerts.slice(0, index),
                    { ...state.alerts[index], read_status: 'read' },
                    ...state.alerts.slice(index + 1),
                ];
            }
        },
        checkAlertsToRead: (state) => {
            state.toRead = !state.alerts.find((alert) => alert.read_status);
        },
        setAlertPanel: (state, action: PayloadAction<AlertsPanelWithId | undefined>) => {
            if (action.payload) {
                const panel: AlertsPanelWithId = action.payload;
                state.alertsPanels[panel.id] = { ...panel };
            }

            VizStore.setAlertsPanels(current(state.alertsPanels));
        },
        deleteAlertPanel: (state, action: PayloadAction<string>) => {
            delete state.alertsPanels[action.payload];

            VizStore.setAlertsPanels(state.alertsPanels);
        },
    },
});

/*===============================
            Actions
===============================*/

export const { setAlerts, setAlertToRead, checkAlertsToRead, setAlertPanel, deleteAlertPanel } =
    SimulationViewSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectAlerts = (state: RootState) => state.alertsSlice.alerts;
export const selectAlertsToRead = (state: RootState) => state.alertsSlice.toRead;
export const selectUnreadAlertsList = createSelector(
    (state: RootState) => state.alertsSlice.alerts,
    (alerts) => alerts.filter((alert) => alert.read_status === 'unread')
);
export default SimulationViewSlice.reducer;
