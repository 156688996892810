import { Visualization } from 'components/Visualizer/visualization';
import { getCloserSmallerNumber } from './../util';
import { apolloClient } from 'app/clients/apolloClient';
import { GETSIMULATIONKPIS, GETSIMULATIONMOVEMENTS } from '_queries';
import { SimulationKPIsQueryData, SimulationQueryData } from '_types/queries';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { SimElevatorsData, SimulatedKPIs, SimulatedMovementsDataType } from '_types/simulations';
import { EnhancedStore } from '@reduxjs/toolkit';
import { store } from 'app/store';
import {
    setSimulationPeopleOnPlatform,
    setSimulationReached,
} from 'features/StationView/SimulationSlice';
import { MetadataElevator } from '_types/features';

export class SimulationDataManager {
    viz: Visualization;
    client: ApolloClient<NormalizedCacheObject>;
    movementsData: SimulatedMovementsDataType;
    stationName: string;
    store: EnhancedStore;
    querying: boolean;
    trainMovement: {
        east_metro: {
            [timestamp: string]: {
                direction: 'to_east' | 'from_west';
                percentage: number;
            };
        };
        west_metro: {
            [timestamp: string]: {
                direction: 'from_east' | 'to_west';
                percentage: number;
            };
        };
    };
    KPIsData: SimulatedKPIs;
    elevatorsData: SimElevatorsData;
    stationsElevators: MetadataElevator[];

    constructor(stationName: string) {
        this.stationName = stationName;
        this.client = apolloClient;
        this.store = store;
        this.movementsData = {};
        this.querying = false;
        this.trainMovement = {
            east_metro: {},
            west_metro: {},
        };
        this.KPIsData = { peopleCount: {} };
        this.elevatorsData = {};
    }

    public SetVisualization(viz: Visualization, elevators: MetadataElevator[]) {
        this.viz = viz;
        this.stationsElevators = elevators;
    }

    public QueryData(simulationID: string, startMs: number, endMs: number) {
        const minStep = 100000;
        const intervals: number[] = [];
        const steps = Math.ceil((endMs - startMs) / minStep);
        for (let i = 0; i < steps; i++) {
            intervals[i] = startMs + i * minStep;
        }
        intervals[steps] = endMs;

        this.client
            .query<SimulationKPIsQueryData>({
                query: GETSIMULATIONKPIS,
                variables: {
                    startMs,
                    endMs,
                    simulationID,
                },
            })
            .then((res) => {
                res.data.listSimPeopleCountLevel.forEach((peopleCountSlice) =>
                    peopleCountSlice.items.forEach((peopleCount) => {
                        if (!this.KPIsData.peopleCount[peopleCountSlice.time_ms])
                            this.KPIsData.peopleCount[peopleCountSlice.time_ms] = {};
                        this.KPIsData.peopleCount[peopleCountSlice.time_ms][
                            peopleCount.floor_name
                        ] = +peopleCount.passenger_count;
                    })
                );
            });

        this.querying = true;
        this.QueryBatch(intervals, 0, simulationID);
    }

    private QueryBatch(intervals: number[], index: number, simulationID: string) {
        if (index === intervals.length - 1 || this.querying === false) {
            this.querying = false;
            return;
        }
        this.client
            .query<SimulationQueryData>({
                query: GETSIMULATIONMOVEMENTS,
                variables: {
                    startMs: intervals[index],
                    endMs: intervals[index + 1],
                    simulationID,
                },
            })
            .then((res) => {
                res.data.listSimPeopleEvents.forEach((slice) => {
                    this.movementsData[slice.time_ms] = slice.movements.map((agent) => ({
                        ObjectID: +agent.ObjectID,
                        ObjectType: +agent.ObjectType,
                        position: JSON.parse(agent.position),
                    }));
                });

                res.data.listSimMetroEvents.forEach((events) => {
                    events.items.forEach((movement) => {
                        for (let i = 0; i < 50; i++) {
                            if (movement.state === 'stopped') return;

                            const isIncoming = movement.state === 'arriving';
                            const timestamp = +events.time_ms + (isIncoming ? -i * 100 : i * 100);
                            const percentage = isIncoming ? 1 - i / 49 : i / 49;
                            const direction =
                                movement.direction === 'east_metro'
                                    ? isIncoming
                                        ? 'from_west'
                                        : 'to_east'
                                    : isIncoming
                                    ? 'from_east'
                                    : 'to_west';

                            this.trainMovement[movement.direction][timestamp] = {
                                direction,
                                percentage,
                            };
                        }
                    });
                });

                res.data.listSimElevatorEvents.forEach((events) => {
                    this.elevatorsData[events.time_ms] = {};
                    events.items.forEach(
                        (elevEvent) =>
                            (this.elevatorsData[events.time_ms][elevEvent.elevator_id] = {
                                x: +elevEvent.x,
                                y: +elevEvent.y,
                                z: +elevEvent.z,
                            })
                    );
                });

                store.dispatch(setSimulationReached(intervals[index + 1]));

                this.QueryBatch(intervals, index + 1, simulationID);
            })
            .catch((e) => console.error(e));
    }

    public setSlice(timestamp: number) {
        const peopleTimestamp = getCloserSmallerNumber(
            Object.keys(this.movementsData) as unknown as number[],
            timestamp
        );
        const currentPeopleSlice = this.movementsData[peopleTimestamp];

        const peopleAsset =
            currentPeopleSlice?.map((agent) => {
                const obj = {
                    position: { x: +agent.position.x, y: +agent.position.y, z: +agent.position.z },
                };
                return obj;
            }) ?? [];

        this.viz.people.UpdateMeshes(peopleAsset);

        for (const trainDirection in this.trainMovement) {
            const trainTimestamp = getCloserSmallerNumber(
                Object.keys(this.trainMovement[trainDirection]) as unknown as number[],
                timestamp
            );

            const trainPosition = this.trainMovement[trainDirection][trainTimestamp];

            if (trainPosition) {
                // console.log(trainPosition.direction, trainPosition.percentage);

                this.viz.static.trainAssets.UpdateTrainStatus(
                    trainPosition.direction,
                    trainPosition.percentage
                );
            } else {
                this.viz.static.trainAssets.UpdateTrainStatus(
                    trainDirection === 'east_metro' ? 'from_west' : 'from_east',
                    0
                );
            }
        }

        const elevTimestamp = getCloserSmallerNumber(
            Object.keys(this.elevatorsData) as unknown as number[],
            timestamp
        );
        const currentElevSlice = this.elevatorsData[elevTimestamp] || {};
        Object.entries(currentElevSlice).forEach(([id, position]) => {
            const elevatorAsset = this.stationsElevators.find((elevator) => elevator.number == +id);
            if (!elevatorAsset) return;
            this.viz.static.SetAssetsFeatures([
                {
                    name: elevatorAsset.name,
                    position: position,
                },
            ]);
        });

        if (this.KPIsData.peopleCount) {
            const peopleCount = this.KPIsData.peopleCount;
            const peopleCountTimestamp = getCloserSmallerNumber(
                Object.keys(peopleCount) as unknown as number[],
                timestamp
            );

            const currentPeopleCount = peopleCount[peopleCountTimestamp];

            const peopleOnPlatform = currentPeopleCount?.['Laituritaso'] || 0;

            store.dispatch(setSimulationPeopleOnPlatform(peopleOnPlatform));
        }
    }

    public StopQuery() {
        this.querying = false;
    }
}
