import { upArrow } from './geometries';
import { Viewport } from './viewport';
import * as THREE from 'three';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';

export class ArrowAssets {
    vpt: Viewport;
    arrowBlob: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial[]>;
    scene: THREE.Group;
    defaultColor = new THREE.Color(0x65d2d1);
    enabled = false;
    meshes: Array<THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial[]>> = [];
    axisYLimits = { bottom: -Infinity, top: Infinity };
    hiddenAsset = false;

    constructor(vpt: Viewport) {
        this.vpt = vpt;
        this.arrowBlob = new THREE.Mesh();
        this.scene = new THREE.Group();
        this.scene.name = 'ArrowScene';

        this.LoadModel();
        this.vpt.scene.add(this.scene);
    }

    private DefineMaterial = (): THREE.MeshBasicMaterial[] => {
        const createMaterial = (opacity: number) =>
            new THREE.MeshBasicMaterial({
                color: this.defaultColor,
                opacity,
                transparent: true,
            });

        return [createMaterial(0.2), createMaterial(0.5), createMaterial(0.7)];
    };

    private startVisibilityLoop = (
        mesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial[]>
    ) => {
        const meshMaterials = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
        let index = 0;
        let allVisible = false;

        setInterval(() => {
            if (!allVisible) {
                if (index < meshMaterials.length) {
                    meshMaterials[index].opacity = Math.min(meshMaterials[index].opacity + 0.3, 1);
                    if (meshMaterials[index].opacity >= 1) index++;
                } else {
                    allVisible = true;
                    index = 0;
                }
            } else {
                if (index < meshMaterials.length) {
                    meshMaterials[index].opacity = Math.max(meshMaterials[index].opacity - 0.3, 0);
                    if (meshMaterials[index].opacity <= 0) index++;
                } else {
                    allVisible = false;
                    index = 0;
                }
            }
        }, 100);
    };

    private LoadModel = () => {
        const arrows = Array.from({ length: 3 }, (_, i) => {
            const arrowGeometry = upArrow();
            arrowGeometry.translate(0, i / 2, 0);
            return arrowGeometry;
        });

        const mergedGeometry = mergeBufferGeometries(arrows, true);
        this.arrowBlob = new THREE.Mesh(mergedGeometry, this.DefineMaterial());
    };

    public CreateModel = (name: string, v: THREE.Vector3, direction: string) => {
        const existingMesh = this.meshes.find((mesh) => mesh.name === name);
        if (existingMesh) {
            if (direction !== existingMesh.userData.direction) {
                existingMesh.userData.direction = direction;
                existingMesh.rotation.z = direction === 'IN' ? Math.PI / 2 : -Math.PI / 2;
            }
            if (direction === 'NULL') {
                this.scene.remove(existingMesh);
                this.meshes = this.meshes.filter((mesh) => mesh !== existingMesh);
                return;
            }
        }

        if (direction === 'NULL') {
            return;
        }

        const mesh = this.arrowBlob.clone();
        mesh.name = name;
        mesh.position.copy(v);
        mesh.position.setZ(v.z + 0.4);
        mesh.position.setX(v.x + (direction === 'IN' ? 1 : 0));
        mesh.rotation.set(-Math.PI / 2, 0, direction === 'IN' ? Math.PI / 2 : -Math.PI / 2);
        mesh.userData = { direction };

        this.meshes.push(mesh);
        this.scene.add(mesh);

        this.startVisibilityLoop(mesh);
    };

    public SetArrowVisibilty(visibleAssets?: { name: string; opacity?: number }[]) {
        if (visibleAssets) {
            this.meshes.forEach((mesh) => {mesh.visible = false;});
            visibleAssets.forEach((asset) => {
                const mesh = this.meshes.find((m) => m.name === asset.name);
                if (mesh) {
                    mesh.visible = true;
                }
            });
        } else {
            this.meshes.forEach((mesh) => {
                mesh.visible = true;
            });
        }
    }
}
