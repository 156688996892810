import * as THREE from 'three';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';

export const person = (): THREE.BufferGeometry => {
    const upperBodyGeometry = new THREE.CylinderGeometry(0.2, 0.2, 0.4, 6);
    upperBodyGeometry.translate(0, 0.6, 0);
    const lowerBodyGeometry = new THREE.CylinderGeometry(0.2, 0.1, 0.4, 6);
    lowerBodyGeometry.translate(0, 0.2, 0);
    const headGeometry = new THREE.SphereGeometry(0.2, 6, 4);
    headGeometry.translate(0, 1.15, 0);

    const mergedGeometry = mergeBufferGeometries([
        lowerBodyGeometry,
        upperBodyGeometry,
        headGeometry,
    ]);

    return mergedGeometry;
};

export const bike = (): THREE.BufferGeometry => {
    const wheel1 = new THREE.CylinderGeometry(0.25, 0.25, 0.05, 20);
    wheel1.rotateX(Math.PI / 2);
    wheel1.translate(-0.6, 0.25, 0);

    const whhel2 = wheel1.clone();
    whhel2.translate(1.4, 0, 0);

    /* const body = new THREE.BoxGeometry(1.2, 0.6, 0.05, 100, 100, 1);

    const vertices = body.attributes.position;
    const v3 = new THREE.Vector3();
    for (let i = 0; i < vertices.count; i++) {
        v3.fromBufferAttribute(vertices, i);

        if (v3.y < 0.6 * (v3.x + 0.15) - 0.25) v3.y = 0.6 * (v3.x + 0.15) - 0.25;
        if (v3.y < -(v3.x + 0.15) - 0.25) v3.y = -(v3.x + 0.15) - 0.25;

        vertices.setY(i, v3.y);
    }

    body.translate(0.1, 0.4, 0); */

    const body1 = new THREE.CylinderGeometry(0.05, 0.05, 0.75);
    body1.rotateZ(Math.PI / 4);
    body1.translate(-0.25, 0.45, 0);

    const body12 = new THREE.SphereGeometry(0.05);
    body12.translate(0, 0.2, 0);

    const body2 = new THREE.CylinderGeometry(0.05, 0.05, 0.9);
    body2.rotateZ(-((Math.PI * 0.6) / 2));
    body2.translate(0.35, 0.45, 0);

    const body23 = new THREE.SphereGeometry(0.05);
    body23.translate(0.7, 0.7, 0);

    const body3 = new THREE.CylinderGeometry(0.05, 0.05, 1.2);
    body3.rotateZ(Math.PI / 2);
    body3.translate(0.1, 0.7, 0);

    const body31 = new THREE.SphereGeometry(0.05);
    body31.translate(-0.5, 0.7, 0);

    const handle = new THREE.CylinderGeometry(0.04, 0.04, 0.7);
    handle.rotateZ(Math.PI / 2).rotateY(Math.PI / 2);
    handle.translate(0.8, 0.8, 0);

    const mergedGeometry = mergeBufferGeometries([
        wheel1,
        whhel2,
        body1,
        body12,
        body2,
        body23,
        body3,
        body31,
        handle,
    ]);

    return mergedGeometry;
};


export const upArrow = () => {

    const arrowShape = new THREE.Shape();
    arrowShape.moveTo(-0.5, 0);
    arrowShape.lineTo(-0.5, 0.2);
    arrowShape.lineTo(0, 0.4);
    arrowShape.lineTo(0.5, 0.2);
    arrowShape.lineTo(0.5, 0);
    arrowShape.lineTo(0.4, 0.1);
    arrowShape.lineTo(0, 0.25);
    arrowShape.lineTo(0, 0.25);
    arrowShape.lineTo(-0.4, 0.1);
    arrowShape.lineTo(-0.5, 0);


    // Extrude settings (adds depth)
    const extrudeSettings = { depth: 0.1, bevelEnabled: false };

    const arrow = new THREE.ExtrudeGeometry(arrowShape, extrudeSettings);

    return arrow;
};

export const downArrow = () => {
    const geom = new THREE.BoxGeometry(4000, 400, 2000, 1000, 1, 1000);

    const vertices = geom.attributes.position;
    const v3 = new THREE.Vector3();
    for (let i = 0; i < vertices.count; i++) {
        v3.fromBufferAttribute(vertices, i);

        if (v3.x > 500 && v3.x > -1.5 * Math.abs(v3.z) + 2000) {
            v3.x = -1.5 * Math.abs(v3.z) + 2000;
        } else if (v3.x < 500 && v3.z > 400) {
            v3.z = 400;
        } else if (v3.x < 500 && v3.z < -400) {
            v3.z = -400;
        }

        vertices.setX(i, v3.x);
        vertices.setZ(i, v3.z);
    }

    geom.rotateY(Math.PI);
    geom.rotateZ(Math.PI / 6);

    const mergedGeometry = mergeBufferGeometries([geom]);

    return mergedGeometry;
};
