import { CalculateDataZoom } from '_types';

export const isAverage = (name: string) => name.toLowerCase() === 'average';

// calculate zoom value dynamically
// dataSet - data array
// visiblePoints - how many bars or points to be shown in the Chart
// Note: Pass one point less in visibleDataPoint than what you want to show.
// i.e. if you want to show 10 bars then pass 9.
export const calculateDataZoomStart = (dataSet: CalculateDataZoom, visibleDataPoints = 9) =>
    100 - (visibleDataPoints / dataSet?.length) * 100;
