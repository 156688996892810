import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GETINOUTCOUNTWEEKLY, GETINOUTCOUNTWEEKLYAVERAGE } from '_queries';
import { InOutCountWeekly, InOutCountWeeklyAverage, InOutCountWeeklyChart } from '_types';
import BarGraph from 'components/Graphs/BarGraph';
import {
    convertToWeekdays,
    customTooltip,
    getCurrentWeekDates,
    mapAverageToWeekday,
} from 'components/util';
import { weekdaysList } from 'constants/constants';
import { useRoute } from 'wouter';

const PeopleFlowWeeklyChart: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [inOutCount, setInOutCount] = useState<InOutCountWeekly>([]);
    const [average, setAverage] = useState<InOutCountWeeklyAverage>();

    const { data, loading, error } = useQuery<InOutCountWeeklyChart>(GETINOUTCOUNTWEEKLY, {
        variables: { site_id: stationID },
    });
    const {
        data: averageData,
        loading: averageLoading,
        error: averageError,
    } = useQuery<InOutCountWeeklyChart>(GETINOUTCOUNTWEEKLYAVERAGE, {
        variables: { site_id: stationID },
    });

    const { getInOutCountWeeklyChart } = data || {};
    const { getInOutAverageDailyLine } = averageData || {};

    const weekDates: string[] = getCurrentWeekDates();

    useEffect(() => {
        if (!getInOutCountWeeklyChart) return;

        // filter only current week's data
        const filterData = getInOutCountWeeklyChart?.filter((data) => {
            if (weekDates?.includes(data?.local_date)) {
                return data;
            }
        });

        setInOutCount([...filterData]);
    }, [getInOutCountWeeklyChart]);

    useEffect(() => {
        if (!getInOutAverageDailyLine) return;

        setAverage([...getInOutAverageDailyLine]);
    }, [getInOutAverageDailyLine]);

    const transformData = inOutCount?.map((data) => ({
        inOutCount: parseInt(data?.in_counts) + parseInt(data?.out_counts),
        weekday: convertToWeekdays(data?.weekday),
        average: mapAverageToWeekday(data?.weekday, average),
    }));

    return (
        <BarGraph
            title="People Flow for this week"
            data={transformData}
            dimensions={['weekday', 'inOutCount', 'average']}
            xAxisOption={{
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                data: weekdaysList,
                axisLabel: {
                    rotate: 45,
                    align: 'center',
                    margin: 30,
                },
            }}
            yAxisOption={{
                type: 'value',
            }}
            tooltipOption={{
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params) => {
                    return customTooltip(params, params[0]?.name);
                },
            }}
            loading={loading || averageLoading}
            error={error || averageError}
        />
    );
};

export default PeopleFlowWeeklyChart;
