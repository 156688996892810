import React, { useEffect, useState } from 'react';
import { EChartsCoreOption } from 'echarts';
import { GetPeopleOnPlatform, SeriesOptions } from '_types';
import { ApolloError } from '@apollo/client';
import { graphColors, graphTextStyle, legendNames } from 'constants/constants';
import Graph from './Graph';

interface Props {
    title: string;
    data: GetPeopleOnPlatform;
    dimensions: string[];
    tooltipOption?: Record<string, unknown>;
    xAxisOptions?: Record<string, unknown>;
    seriesOptions?: SeriesOptions;
    graphOptions?: Record<string, unknown>;
    loading?: boolean;
    error?: ApolloError;
}

const LineGraph: React.FC<Props> = ({
    title,
    data,
    dimensions,
    tooltipOption,
    xAxisOptions,
    seriesOptions,
    graphOptions,
    loading,
    error,
}) => {
    const [dataSet, setDataSet] = useState<GetPeopleOnPlatform>(data);
    const isError = !dataSet || dataSet?.length === 0 || !!error;

    useEffect(() => {
        setDataSet(data);
    }, [data]);

    const series = dimensions.slice(1).map((name) => ({
        type: 'line',
        name: legendNames[name],
        connectNulls: true,
        ...seriesOptions,
    }));

    const option: EChartsCoreOption = {
        color: [...graphColors],
        legend: {
            top: 'top',
            left: 'right',
            textStyle: { ...graphTextStyle, fontSize: 14 },
        },
        tooltip: {
            trigger: false,
            ...tooltipOption,
        },
        dataset: {
            dimensions: [...dimensions],
            source: [...dataSet],
        },
        xAxis: {
            type: 'category',
            ...xAxisOptions,
            axisLabel: {
                ...graphTextStyle,
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                ...graphTextStyle,
            },
        },
        series: series,
        ...graphOptions,
    };

    return <Graph title={title} option={option} loading={loading} isError={isError} />;
};

export default LineGraph;
