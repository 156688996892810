import { useQuery } from '@apollo/client';
import { GETEQUIPMENTUSAGEDAILY } from '_queries';
import { EquipmentUsage, EquipmentUsageChartData, TooltipParams } from '_types';
import BarGraph from 'components/Graphs/BarGraph';
import { customTooltip, getEquipmentUsageChartAverage, getTodaysDate } from 'components/util';
import React, { useEffect, useState } from 'react';
import { useRoute } from 'wouter';

const EquipmentUsageChart: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [equipmentData, setEquipmentData] = useState<EquipmentUsage[]>([]);

    // get todays date and day
    const { date, dayOfWeek } = getTodaysDate();

    const { data, loading, error } = useQuery<EquipmentUsageChartData>(GETEQUIPMENTUSAGEDAILY, {
        variables: { site_id: stationID, local_date: date },
    });

    const { getEquipmentUsageDailyChart, getEquipmentUsageAverageLine } = data || {};

    useEffect(() => {
        if (!getEquipmentUsageDailyChart || !getEquipmentUsageAverageLine) return;

        const transformData = getEquipmentUsageDailyChart?.map((item) => ({
            ...item,
            average: parseInt(
                getEquipmentUsageChartAverage(getEquipmentUsageAverageLine, item, dayOfWeek)
            )?.toFixed(2),
        }));

        setEquipmentData(transformData);
    }, [getEquipmentUsageDailyChart]);

    return (
        <BarGraph
            title="Equipment usage"
            data={equipmentData}
            dimensions={['eq_name', 'total', 'average']}
            xAxisOption={{
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
            }}
            yAxisOption={{
                type: 'value',
            }}
            tooltipOption={{
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params: TooltipParams) => {
                    return customTooltip(params, params[0]?.name);
                },
            }}
            loading={loading}
            error={error}
        />
    );
};

export default EquipmentUsageChart;
