import React, { useEffect } from 'react';
import { useLocation } from 'wouter';

import './metroLineOverview.scss';
// import { GETWEATHER } from '_queries';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
// import { TrainData, WeatherData } from '_types/queries';
import List from 'components/List/List';
// import { TrainDataManager } from 'dataManagers/liveDatamanager/trainDataManager';
import { useLazyGetDigiTwinUIAPIQuery } from 'app/store';
import { MetroLine, MetroLinesList } from '_types';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import Select, { SelectValue } from 'components/UIComponents/Select/Select';

// const reqSvgs = require.context('../../assets/icons/weather-icons/WeatherSymbol3', false, /\.svg$/);
// const iconPaths = reqSvgs
//     .keys()
//     .filter((s) => s[0] === '.')
//     .reduce((o, s) => {
//         o[s.replace('./', '').replace('.svg', '')] = reqSvgs(s);
//         return o;
//     }, {});

const MetroLineOverview: React.FC = () => {
    const [, setLocation] = useLocation();
    const dispatch = useAppDispatch();

    // STATIONS FETCH
    const [triggerLazyGetDigiTwinAPI, { isSuccess }] =
        useLazyGetDigiTwinUIAPIQuery();
    const [metroLinesList, setMetroLinesList] = React.useState<MetroLinesList>([]);
    const [currentMetroLineSelectoption, setCurrentMetroLineSelectOption] =
        React.useState<SelectValue<string>>();
    // METROLINES
    const [metroLines, setMetroLines] = React.useState<Record<string, MetroLine>>({});

    // GET Groups
    useEffect(() => {
        dispatch(setMainSidebarFull(true));
        // SET METROLINES DATA
        triggerLazyGetDigiTwinAPI('/groups')
            .unwrap()
            .then(({ site_groups }: { site_groups: MetroLinesList }) => {
                setCurrentMetroLineSelectOption({
                    value: site_groups[0]?.group_id,
                    label: site_groups[0]?.name,
                });
                setMetroLinesList(site_groups);
            });
    }, []);

    // GET Stations
    useEffect(() => {
        if (isSuccess) {
            if (metroLinesList.length === 0) return;
            console.log(metroLinesList);
            metroLinesList.forEach((metroLineData) =>
                triggerLazyGetDigiTwinAPI(`/groups/${metroLineData.group_id}`)
                    .unwrap()
                    .then(({ site_list }: { site_list: MetroLine }) => {
                        setMetroLines((s) => ({
                            ...s,
                            [metroLineData.group_id]: site_list,
                        }));
                    })
            );
        }
    }, [isSuccess, metroLinesList]);


    if(metroLinesList.length === 0) return <LoadingPage />;

    return (
        <div id="metroLineOverview">
            <main>
                {/* TODO: get this title dependant on the customer */}
                {/* <div id="title">
                    <div id="metro-sign-container">
                        <div id="metro-sign">
                            <span>M</span>
                        </div>
                        <div id="text">
                            <h2>Helsinki</h2>
                            <h2>Metrolinja</h2>
                        </div>
                    </div>
                    <div id="date-time">
                        <span> {currentDateTime.toLocaleTimeString()} </span>
                        <span>{currentDateTime.toLocaleDateString('en-GB')}</span>
                    </div>
                </div> */}
                <div className="select-metro-line-container">
                    <span>Select a metro line</span>
                    <Select
                        placeholder="Select a metro line"
                        value={currentMetroLineSelectoption}
                        onChange={(e) => setCurrentMetroLineSelectOption(e as SelectValue<string>)}
                        options={metroLinesList.map((metroLineData) => ({
                            value: metroLineData.group_id,
                            label: metroLineData.name,
                        }))}
                    />
                </div>
                {currentMetroLineSelectoption?.value &&
                metroLines[currentMetroLineSelectoption?.value] ? (
                    <List
                        head={
                            <tr>
                                <td>STATION</td>
                            </tr>
                        }>
                        {metroLines[currentMetroLineSelectoption?.value ?? ''].map((station) => (
                            <tr key={station.site_id}>
                                <td>
                                    <div
                                        className={`station-name ${
                                            station.is_active ? 'active' : ''
                                        }`}
                                        onClick={() => {
                                            if (!station.is_active) return;
                                            setLocation(`/stations/${station.site_id}`);
                                        }}>
                                        {station.site_name}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </List>
                ) : (
                    <LoadingPage />
                )}
            </main>
        </div>
    );
};

export default MetroLineOverview;
