import { gql } from '@apollo/client';

/*
Note: cross flow analysis query have below params but fetching only necessary params
query MyQuery($site_id: String) {
    getCrossFlowAnalysis(site_id: $site_id) {
        count
        datetime
        event_hour
        id
        site_id
        ttl_time
        x
        y
        z
    }
}
*/

export const CROSSFLOWANALYSIS = gql`
    query MyQuery($site_id: String) {
        getCrossFlowAnalysis(site_id: $site_id) {
            count
            datetime
            event_hour
            x
            z
        }
    }
`;
