import React from 'react';
import { EChartsCoreOption } from 'echarts';
import Graph from './Graph';
import { ApolloError } from '@apollo/client';
import { graphColors, graphTextStyle } from 'constants/constants';

interface Props {
    title: string;
    data: (string | number)[][];
    dimensions: string[];
    xAxisOption?: Record<string, unknown> & { axisLabel?: Record<string, unknown> };
    yAxisOption?: Record<string, unknown> & { axisLabel?: Record<string, unknown> };
    seriesOption?: Record<string, unknown>;
    tooltipOption?: Record<string, unknown>;
    graphOption?: Record<string, unknown>;
    loading?: boolean;
    error?: ApolloError;
}

const HorizontalBarGraph: React.FC<Props> = ({
    title,
    data,
    dimensions,
    xAxisOption,
    yAxisOption,
    seriesOption,
    tooltipOption,
    graphOption,
    loading,
    error,
}) => {
    const isError = !data || data?.length === 0 || !!error;

    const series = dimensions.slice(1).map((name) => ({
        type: 'bar',
        name,
        connectNulls: true,
        ...seriesOption,
    }));

    const option: EChartsCoreOption = {
        color: [...graphColors],
        legend: {
            top: 'top',
            left: 'right',
            textStyle: { ...graphTextStyle, fontSize: 14 },
        },
        xAxis: {
            ...xAxisOption,
            axisLabel: {
                ...graphTextStyle,
                ...xAxisOption?.axisLabel,
            },
        },
        yAxis: {
            type: 'category',
            ...yAxisOption,
            axisLabel: {
                ...graphTextStyle,
                ...yAxisOption?.axisLabel,
            },
        },
        dataset: {
            dimensions: dimensions,
            source: [...data],
        },
        series: series,
        tooltip: {
            trigger: false,
            ...tooltipOption,
        },
        ...graphOption,
    };

    return (
        <Graph
            title={title}
            option={option}
            loading={loading}
            isError={isError}
            showSelect={true}
        />
    );
};

export default HorizontalBarGraph;
