import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GETINOUTCOUNTHOURLY, GETINOUTCOUNTHOURLYAVERAGE } from '_queries';
import { InOutCountHourly, InOutCountHourlyChart } from '_types';
import BarGraph from 'components/Graphs/BarGraph';
import { calculateDataZoomStart } from 'components/Graphs/graphUtils';
import { useRoute } from 'wouter';
import { checkIfTodaysDate, customTooltip, getAverage } from 'components/util';

const PeopleFlowHourlyChart: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [inOutCount, setInOutCount] = useState<InOutCountHourly>([]);

    const [getInOutCountHourly, { data, loading, error }] = useLazyQuery<InOutCountHourlyChart>(
        GETINOUTCOUNTHOURLY,
        {
            variables: { site_id: stationID },
        }
    );
    const [
        inOutCountHourlyAverage,
        { data: averageData, loading: averageLoading, error: averageError },
    ] = useLazyQuery<InOutCountHourlyChart>(GETINOUTCOUNTHOURLYAVERAGE, {
        variables: { site_id: stationID },
    });

    const { getInOutCountHourlyChart } = data || {};
    const { getInOutAverageHourlyLine } = averageData || {};

    const dataZoomStart = calculateDataZoomStart(inOutCount, 7);

    const callHourlyTask = () => {
        const now = new Date();
        const minutes = now.getMinutes();

        if (minutes === 0) {
            getInOutCountHourly();
            inOutCountHourlyAverage();
            console.log(
                'getInOutCountHourly query triggered at',
                now.toLocaleTimeString(),
                'data:',
                data
            );
        }
    };

    useEffect(() => {
        // after every minute, check if its an exact hour or not i.e. 12:00, 1:00
        const interval = setInterval(() => {
            callHourlyTask();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getInOutCountHourly();
        inOutCountHourlyAverage();
    }, []);

    useEffect(() => {
        if (!getInOutCountHourlyChart || !getInOutAverageHourlyLine) return;

        // get all data for today only
        const getCurrentData = getInOutCountHourlyChart?.filter((data) =>
            checkIfTodaysDate(data?.local_time)
        );

        const mapAverageToData = getCurrentData.map((data) => ({
            hour: data?.hour,
            in_counts: data.in_counts,
            out_counts: data.out_counts,
            average: getAverage(data?.weekday, data.hour, getInOutAverageHourlyLine),
            local_time: data?.local_time,
            weekday: data?.weekday
        }));

        setInOutCount([...mapAverageToData]);
    }, [getInOutCountHourlyChart, getInOutAverageHourlyLine]);

    const transformData = inOutCount?.map((data) => ({
        inCount: parseInt(data?.in_counts),
        outCount: parseInt(data?.out_counts),
        hour: data?.hour,
        time: data?.local_time,
        average: data?.average,
    }));

    return (
        <BarGraph
            title="People Flow for today"
            data={transformData}
            dimensions={['time', 'inCount', 'outCount', 'average']}
            xAxisOption={{
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    formatter: (param) => {
                        const date = new Date(param);
                        const hours = date?.getHours();
                        return `${hours - 1}-${hours}`;
                    },
                },
            }}
            yAxisOption={{
                type: 'value',
            }}
            seriesOption={{
                stack: 'Stack',
            }}
            tooltipOption={{
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params) => {
                    const { hour, time } = params[0]?.value || {};
                    const date = new Date(time);
                    const dateStr = date?.toISOString()?.split('T')[0];

                    const name = `<b>Date:</b> ${dateStr}<br/><b>Time</b>: ${hour - 1}-${hour}`;

                    return customTooltip(params, name);
                },
            }}
            graphOption={{
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        xAxisIndex: [0],
                        start: dataZoomStart,
                        end: 100,
                    },
                    {
                        type: 'inside',
                        xAxisIndex: [0],
                    },
                ],
            }}
            loading={loading || averageLoading}
            error={error || averageError}
        />
    );
};

export default PeopleFlowHourlyChart;
