import {
    EquipmentAverageData,
    EquipmentUsage,
    InOutCountHourlyAverage,
    InOutCountWeeklyAverage,
    TooltipParams,
    TransformedMetroUsageData,
} from '_types';
import { CrossFlowData } from '_types/analysis';
import { SelectValue } from './UIComponents/Select/Select';
import { crossFlowhighloghtColor } from 'constants/constants';

export function asciiFromString(string: string): number {
    let result = 0;
    for (let i = 0; i < string.length; i++) {
        result += string.charCodeAt(i);
    }
    return result;
}

export const doubleDigit = (number: number) => {
    return ('00' + number).slice(-2);
};

export function TimeFormatterFromSeconds(secondsTimestamp: number, seconds?: boolean) {
    const hours = Math.floor(secondsTimestamp / 3600);
    const mins = Math.floor((secondsTimestamp % 3600) / 60);
    const secs = Math.floor(secondsTimestamp % 60);

    return (
        (hours !== 0 ? doubleDigit(hours) + ':' : '') +
        doubleDigit(mins) +
        (seconds ? ':' + doubleDigit(secs) : '')
    );
}

// method to get value in milliseconds from string in the formats:
// hh:mm:ss or mm:ss
export function GetMillisecondsValueFromString(
    string: string,
    format: 'hh:mm:ss' | 'mm:ss' | 'hh:mm'
) {
    const split = string.split(':');
    if (format === 'hh:mm:ss') {
        return (+split[0] * 3600 + +split[1] * 60 + +split[2]) * 1000;
    } else if (format === 'mm:ss') {
        return (+split[0] * 60 + +split[1]) * 1000;
    } else {
        return (+split[0] * 3600 + +split[1] * 60) * 1000;
    }
}

export function capitalize(string?: string) {
    if (!string) return '';
    return string[0].toUpperCase() + string.slice(1);
}

// convert unix timestamp into HH:MM format and also make seconds as 0 as
// it might cause graph not to be plotted on exact x-axis
export const formatTimestamp = (timestamp: string) => {
    const date = new Date(parseInt(timestamp) * 1000);
    date?.setSeconds(0, 0);
    const hours = date?.getHours();
    const minutes = date?.getMinutes()?.toString()?.padStart(2, '0');
    return `${hours}:${minutes}`;
};

// for weekdays data API is giving values in number. this function is used to convert
// those number into wekdays i.e. 0=Monday
export const convertToWeekdays = (weekday: number) => {
    switch (weekday) {
        case 0:
            return 'Monday';
        case 1:
            return 'Tuesday';
        case 2:
            return 'Wednesday';
        case 3:
            return 'Thursday';
        case 4:
            return 'Friday';
        case 5:
            return 'Saturday';
        case 6:
            return 'Sunday';
        default:
            return '';
    }
};

// format metro usage data for multiple bar graph
export const formatMetroUsageData = (data: TransformedMetroUsageData[] | undefined) => {
    const carMap: { [key: string]: string[] } = {};

    data?.forEach((item) => {
        if (!carMap[item?.car]) {
            carMap[item?.car] = [];
        }
        carMap[item?.car]?.push(parseFloat(item?.door_count)?.toFixed());
    });

    return Object.entries(carMap)?.map(([car, doorCounts]) => [
        car,
        ...doorCounts.map((value) => parseFloat(value)),
    ]);
};

// custom tooltip for charts
export const customTooltip = (params: TooltipParams, name: string | undefined) => {
    return `
        <div>
            <div>${name}</div></br>
            ${params
                ?.map((paramValue) => {
                    const { color, seriesName, seriesIndex, data, encode, dimensionNames } =
                        paramValue || {};

                    const isTypeOfDataIsArray = Array.isArray(data);
                    return `
                    <div style="display: flex; align-items: center;">
                        <div style="width: 12px; height: 12px; background-color: ${color}; margin-right: 8px;border: 1px solid"></div>
                        <div><strong>${seriesName}</strong> &nbsp&nbsp ${
                        isTypeOfDataIsArray
                            ? data[seriesIndex + 1]
                            : data[dimensionNames[parseInt(encode?.y)]]
                    }</div>
                    </div>`;
                })
                ?.join('')}
            </div>
        `;
};

// map average values to particular weekdays
export const mapAverageToWeekday = (day: number, average: InOutCountWeeklyAverage | undefined) => {
    const weekday = convertToWeekdays(day);
    const averageCount = average?.find((average) => average?.weekday === weekday);
    const totalCount = averageCount?.total_counts || '0';

    return parseFloat(totalCount)?.toFixed(2);
};

// get current week dates between monday to sunday
// this array will reset every monday
export const getCurrentWeekDates = () => {
    const today = new Date();
    const currentDay = today.getDay();

    const daysToMonday = currentDay === 0 ? 6 : currentDay - 1;

    const monday = new Date(today);
    monday.setDate(today.getDate() - daysToMonday);

    const weekArray: string[] = [];
    for (let i = 0; i < 7; i++) {
        const currentDayOfWeek = new Date(monday);
        currentDayOfWeek.setDate(monday.getDate() + i);
        weekArray.push(currentDayOfWeek.toISOString().split('T')[0]);
    }
    return weekArray;
};

// check if date passed is today's date or not
export const checkIfTodaysDate = (date: string) => {
    if (!date) return false;

    const targetDate = new Date(date);
    const today = new Date();

    const isSameDay =
        targetDate?.getFullYear() === today?.getFullYear() &&
        targetDate?.getMonth() === today?.getMonth() &&
        targetDate?.getDate() === today?.getDate();

    return isSameDay;
};

export const getAverage = (
    weekday: number,
    hour: number,
    getInOutAverageHourlyLine: InOutCountHourlyAverage
) => {
    const count = getInOutAverageHourlyLine?.find(
        (data) => data?.weekday === convertToWeekdays(weekday) && data?.hour === hour
    )?.total_counts;

    return count || '0';
};

// This function returns hex color code based on values. it calculates as follows:
// It calculates difference between min and max value, and divide that difference into 3 equal parts
// and returns light color for part whose value near minCount, default color for middle part,
// and dark color for value near maxCount
export const getColorBasedOnCount = (count: number, minCount: number, maxCount: number) => {
    const lightColor = crossFlowhighloghtColor.light; // Lighter shade
    const darkColor = crossFlowhighloghtColor.base; // Base color (default)
    const darkerColor = crossFlowhighloghtColor.dark; // Darker shade

    const normalized = (count - minCount) / (maxCount - minCount);

    const clamped = Math.min(Math.max(normalized, 0), 1);
    if (clamped < 0.33) {
        return { color: lightColor, opacity: 0.15 };
    } else if (clamped < 0.66) {
        return { color: darkColor, opacity: 0.3 };
    } else {
        return { color: darkerColor, opacity: 0.7 };
    }
};

// get max value from passed array of objects for count property
export const getMaxValueFromArray = (crossFlowData: CrossFlowData[]) =>
    crossFlowData?.reduce((max, currentObj) => {
        return currentObj?.count > max ? currentObj?.count : max;
    }, crossFlowData[0]?.count);

// get min value from passed array of objects for count property
export const getMinValueFromArray = (crossFlowData: CrossFlowData[]) =>
    crossFlowData?.reduce((min, currentObj) => {
        return currentObj?.count < min ? currentObj?.count : min;
    }, crossFlowData[0]?.count);

// from current date data, get count of number of occurances at particular hour i.e [ {1: 4},{2:6} ]
export const getCrossFlowCountperHour = (dataForCurrentDate: CrossFlowData[]) => {
    const eventCounts = dataForCurrentDate?.reduce<{ [key: string]: number }>((acc, curr) => {
        const hour = curr.event_hour;
        acc[hour] = (acc[hour] || 0) + 1;
        return acc;
    }, {});

    return Object.entries(eventCounts)?.map(([event_hour, count]) => ({
        event_hour,
        count,
    }));
};

// get unique dated present in array of objects for datetime property
export const getUniqueDates = (getCrossFlowAnalysis: CrossFlowData[]) => {
    const uniqueDates =
        getCrossFlowAnalysis?.reduce<SelectValue<string>[]>((acc, { datetime }) => {
            const date = datetime?.split(' ')[0];

            if (!acc.some((option) => option.value === date)) {
                acc.push({ value: date, label: date });
            }
            return acc;
        }, []) ?? [];

    return uniqueDates;
};

export const getEquipmentUsageChartAverage = (
    getEquipmentUsageAverageLine: EquipmentAverageData[],
    item: EquipmentUsage,
    dayOfWeek: string
) => {
    const average = getEquipmentUsageAverageLine?.find(
        (averageData) =>
            averageData?.weekday === dayOfWeek && averageData?.eq_name === item?.eq_name
    )?.average_counts;

    return average || '0';
};

export const getTodaysDate = () => {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' });

    return { date: formattedDate, dayOfWeek: dayOfWeek };
};
