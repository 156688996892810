import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type CrossFlowData = {
    datetime: string;
    event_hour: number;
    x: string;
    z: string;
    count: number;
};

interface DashboardSliceState {
    crossFlowData: CrossFlowData[];
}

const initialState: DashboardSliceState = {
    crossFlowData: [],
};

/*===============================
              Slice
===============================*/

export const AnalysisSlice = createSlice({
    name: 'analysisData',
    initialState,
    reducers: {
        setCrossFlowData: (state, action: PayloadAction<CrossFlowData[]>) => {
            state.crossFlowData = action.payload;
        },
    },
});

/*===============================
            Actions
===============================*/

export const { setCrossFlowData } = AnalysisSlice.actions;

/*===============================
           Selectors
===============================*/

export const getCrossFlowData = (state: RootState) => state.analysisSlice.crossFlowData;

export default AnalysisSlice.reducer;
