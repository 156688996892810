import { GraphQLErrors, NetworkErrors } from '_types';
import { toast } from 'react-toastify';

export const secToMins = (num: number | undefined) => {
    if (!num) return '--';
    return Math.floor(num / 60) + ':' + ('0' + Math.floor(num % 60)).slice(-2);
};

const triggerToast = (message: string) => {
    //check if sidebar is present then add margin from left to make it align with
    //alerts toast
    const isSidebarPresent = document?.getElementById('statsSidebar');
    toast.error(`${message}`, {
        position: isSidebarPresent ? 'bottom-left' : 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        style: isSidebarPresent
            ? {
                  marginLeft: 'calc(var(--sidebar-width) + 8rem)',
                  minWidth: 'fit-content',
                  maxWidth: 450,
              }
            : { minWidth: 'fit-content', maxWidth: 450 },
    });
};

export const handleNetworkErrors = (error: NetworkErrors, errorType: string) => {
    if (errorType === 'networkError') {
        if ('errors' in error) {
            const { errors } = error || {};
            errors?.forEach(({ message }) => {
                triggerToast(message);
                throw new Error(`${message}`);
            });
        }

        throw new Error(`${error}`);
    } else {
        throw new Error('Unknown Error Occuered!');
    }
};

export const handleGraphQLErrors = (error: GraphQLErrors, errorType: string) => {
    if (errorType === 'graphQLError') {
        error?.forEach(({ message }) => {
            triggerToast(message);
            throw new Error(`${message}`);
        });
    } else {
        throw new Error('Unknown Error Occuered!');
    }
};
