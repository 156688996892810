import * as THREE from 'three';
import { Viewport } from './viewport';
import { getColorBasedOnCount } from 'components/util';
import { CrossFlowData } from '_types/analysis';

export class AnalysisAssets {
    vpt: Viewport;
    scene: THREE.Group;
    circle: THREE.Mesh[];
    scaleFactor = 1;

    public constructor(vpt: Viewport) {
        this.vpt = vpt;
        this.scene = new THREE.Group();
        this.circle = [];
        this.scene.name = 'AnalysisScene';

        this.vpt.scene.add(this.scene);
        this.vpt.updates.push(this.animateCircle.bind(this));
    }

    private createModel = (
        count: number,
        minCount: number,
        maxCount: number
    ): THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial> => {
        const geometry = new THREE.CircleGeometry(1.5, 32);
        const material = new THREE.MeshLambertMaterial({
            transparent: true,
            side: THREE.DoubleSide,
            depthWrite: false,
            ...getColorBasedOnCount(count, minCount, maxCount),
        });
        return new THREE.Mesh(geometry, material);
    };

    public clearCrossFlowModels() {
        this.circle.forEach((mesh) => {
            this.scene.remove(mesh);

            if (mesh.geometry) mesh.geometry.dispose();
            if (Array.isArray(mesh.material)) {
                mesh.material.forEach((material) => {
                    if (material) material.dispose();
                });
            } else {
                if (mesh.material) mesh.material.dispose();
            }
        });

        this.circle = [];
        return;
    }

    public LoadModel(data: CrossFlowData, minCount: number, maxCount: number) {
        const { x, z, count } = data || {};
        // y is constant throughout.
        const y = -20.75;
        const mesh = this.createModel(count, minCount, maxCount);
        mesh.rotation.x = Math.PI / 2;
        mesh.position.set(parseInt(x), y, parseInt(z));

        this.circle.push(mesh);
        this.scene.add(mesh);
    }

    // circle scaling animation
    private animateCircle(): void {
        const time = Date.now() * 0.005;

        this.circle?.forEach((mesh) => {
            if (mesh instanceof THREE.Mesh) {
                const scaleFactor = 1 + Math.sin(time * 0.3) * 0.3;
                mesh.scale.set(scaleFactor, scaleFactor, scaleFactor);
            }
        });
    }
}
