export const weekdaysList = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

export const legendNames = {
    inOutCount: 'Total Passengers',
    plat_count: 'People',
    inCount: 'Incoming',
    outCount: 'Outgoing',
    average: 'Average',
    total: 'Total',
};

export const graphTextStyle = {
    color: '#fff',
    fontSize: 12,
};

export const graphColors = ['#7296F9', '#4373F7', '#1450F5'];

export const metroUsageOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'history', label: 'History' },
];

export const crossFlowHoursArray = Array.from({ length: 24 }, (_, index) => (index + 1).toString());

export const crossFlowhighloghtColor = {
    light: '#6A90FF',
    base: '#4373F7',
    dark: '#2F5FD1',
};
