import React from 'react';
import StationTwinLive from './StationTwinLive/StationTwinLive';
import './stationTwin.scss';
import DashBoard from 'components/DashBoards/DashBoard';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    selectMainView,
    setMainView,
    MainViewOptions,
} from 'features/StationView/StationViewSlice';
import { MainViewNavBar } from 'components/MainViewNavBar/MainViewNavBar';
import StatsSidebar from 'components/StatsSidebar/StatsSidebar';
import { useQuery } from '@apollo/client';
import { GETLATENCY } from '_queries';
import { useRoute } from 'wouter';
import VirtualOperatorView from 'components/VirtualOperatorView/VirtualOperatorView';
import { selectAlertsToRead } from 'features/StationView/AlertSlice';
import SegmentControl from 'components/UIComponents/SegmentControl/SegmentControl';
import StationTwinAnalysis from './StationTwinAnalysis/StationTwinAnalysis';

export const StationTwinMainView: React.FC = () => {
    const mainView = useAppSelector(selectMainView);
    const dispatch = useAppDispatch();
    const setView = (view) => {
        dispatch(setMainView(view));
    };

    React.useEffect(() => {
        const localStorageMainView = localStorage.getItem('mainView');
        if (localStorageMainView) dispatch(setMainView(localStorageMainView as MainViewOptions));
        else dispatch(setMainView('live'));

        return () => localStorage.setItem('mainView', 'live');
    }, []);

    const alertsToRead = useAppSelector(selectAlertsToRead);

    return (
        <div>
            <div id="stationTwin">
                <main>
                    <MainViewNavBar>
                        <LiveDot />
                        <SegmentControl
                            options={['live', 'dashboard', "analysis" /* , 'virtual operator' */]}
                            active={mainView}
                            eOnChange={setView}
                            alerts={alertsToRead ? ['virtual operator'] : []}
                            size="sm"
                        />
                    </MainViewNavBar>
                    {mainView === 'dashboard' ? (
                        <DashBoard />
                    ) : mainView === 'analysis' ? (
                        <StationTwinAnalysis />
                    ) : (
                        <>
                            <StatsSidebar />
                            <div className="stationInterface">
                                <StationTwinLive />
                            </div>
                        </>
                    )}
                </main>
            </div>
        </div>
    );
};

const LiveDot: React.FC = () => {
    const view = useAppSelector(selectMainView);
    // const { data, startPolling, stopPolling } = useQuery(GETLATENCY);

    const isLive = view === 'live' || view === 'virtual operator';

    // React.useEffect(() => {
    //     if (view === 'live') {
    //         startPolling(500);
    //     } else {
    //         stopPolling();
    //     }
    //     return stopPolling;
    // }, [view]);

    return (
        <div className="live-dot-container">
            {/* {isLive && data && (
                <span className="latency">{Math.floor(data.getLatencyLive.latency) + ' ms'}</span>
            )} */}
            <div className={'live-dot ' + (isLive ? 'live' : 'disabled')}></div>
        </div>
    );
};
