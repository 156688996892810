import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartsCoreOption } from 'echarts';
import Spinner from 'components/LoadingPage/Spinner';
import Select, { SelectValue } from 'components/UIComponents/Select/Select';
import { useAppDispatch } from 'app/hooks';
import { setMetroUsageFrequency } from 'features/StationView/DashboardSlice';
import { metroUsageOptions } from 'constants/constants';

interface Props {
    title: string;
    option: EChartsCoreOption;
    isError: boolean | undefined;
    loading: boolean | undefined;
    showSelect?: boolean | undefined;
}

const Graph: React.FC<Props> = ({ title, option, isError, loading, showSelect = false }) => {
    const dispatch = useAppDispatch();

    const [selectMetroUsageValue, setSelectMetroUsageValue] = useState<SelectValue<string>>(
        metroUsageOptions[0]
    );

    useEffect(() => {
        if (title?.includes('West')) {
            dispatch(setMetroUsageFrequency({ side: 'west', value: selectMetroUsageValue?.value }));
        }

        if (title?.includes('East')) {
            dispatch(setMetroUsageFrequency({ side: 'east', value: selectMetroUsageValue?.value }));
        }
    }, [selectMetroUsageValue, title]);

    return (
        <>
            <div className="chart-container">
                <div className="chart-title">
                    <span>{title}</span>
                    {showSelect && (
                        <div>
                            <Select
                                placeholder="Select"
                                value={selectMetroUsageValue}
                                onChange={(e) => setSelectMetroUsageValue(e as SelectValue<string>)}
                                options={metroUsageOptions}
                            />
                        </div>
                    )}
                </div>
                <br />
                {loading && (
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                )}
                {!loading && isError && (
                    <div className="error-container spinner-container">
                        Ops, something went wrong
                    </div>
                )}
                {!loading && !isError && <ReactEcharts option={option} className="charts-expand" />}
            </div>
        </>
    );
};

export default Graph;
