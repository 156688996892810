import {
    ApolloClient,
    NormalizedCacheObject,
    ObservableQuery,
    OperationVariables,
} from '@apollo/client';
import { GET_EQUIPMET_STATUS, SUBSCRIBE_TO_EQUIPMET_STATUS } from '_queries';
import { apolloClient } from 'app/clients/apolloClient';
import { Visualization } from 'components/Visualizer/visualization';
import { AssetData } from '_types';
import { EquipmentData } from '_types/queries';
import { changeAssetsFeatures } from '../util';
import { Subscription } from '@apollo/client/node_modules/zen-observable-ts/module';
import { LDM } from './liveDataManager';
import { MetadataEquipments } from '_types/features';

export class EquipmentDataManager implements LDM {
    viz: Visualization;
    client: ApolloClient<NormalizedCacheObject>;
    query: ObservableQuery<EquipmentData, OperationVariables>;
    assets: AssetData[];
    assetSubscription: Subscription;
    equipments: MetadataEquipments;
    site_id: string;

    constructor(equipments: MetadataEquipments, site_id: string) {
        this.equipments = equipments;

        this.client = apolloClient;
        this.assets = [];

        this.site_id = site_id;
    }

    public SetMainViewSubscription(viz: Visualization) {
        this.viz = viz;

        if (!this.site_id) return;

        this.client
            .query({
                query: GET_EQUIPMET_STATUS,
                variables: { site_id: this.site_id },
            })
            .then(({ data }: { data: { getStationEquipmentStatus: EquipmentData[] } }) => {
                const { getStationEquipmentStatus } = data || {};
                if (getStationEquipmentStatus) {
                    data?.getStationEquipmentStatus.forEach((eqpStatus) => {
                        this.SubscribeFunctionMainView(eqpStatus);
                    });
                } else {
                    console.warn('No data received or equipment status is null');
                }
            })
            .catch((e) => {
                console.error('Error while fetching equipment status:', e);
            });

        this.assetSubscription = this.client
            .subscribe({
                query: SUBSCRIBE_TO_EQUIPMET_STATUS,
                variables: { site_id: this.site_id },
            })
            .subscribe({
                next: ({ data }) => {
                    const { onEquipmentStatus } = data || {};

                    if (onEquipmentStatus) {
                        this.SubscribeFunctionMainView(onEquipmentStatus);
                    } else {
                        console.warn('No data received or equipment status subscription is null.');
                    }
                },
                error: (e) =>
                    console.error('Error while fetching equipment status subscription:', e),
            });
    }

    private SubscribeFunctionMainView(eqpStatus: EquipmentData) {
        const assetsData: AssetData[] = this.MapEventsToEquipments(eqpStatus);
        this.assets = [...this.assets, ...assetsData];
        this.viz.static.SetAssetsFeatures(this.assets);
    }

    private MapEventsToEquipments(events: EquipmentData) {
        const { escalators, elevators } = this.equipments || {};
        
        const assets =
            escalators?.find((e) => e.name == events?.eq) ||
            elevators?.find((e) => e.name === events?.eq);
        if (!assets) {
            console.error('Equipment ', events.eq, ' not found');
            return [];
        }

        const equipmentData = changeAssetsFeatures(assets, events) as AssetData[];
        return equipmentData;
    }

    public StopMainViewSubscription() {
        if (!this.assetSubscription) return;
        this.assetSubscription.unsubscribe();
    }
}
