import React, { useEffect } from 'react';
import { Viewport } from './viewport';
import { Visualization } from './visualization';

import './visualizer.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setCameraViews, setCurrentCameraView } from 'features/StationView/StationViewSlice';
import { DigiTwinUIAPIQueryHookResult, store, useGetDigiTwinUIAPIQuery } from 'app/store';
import { AlertsPanel, CameraViewsQuery, View, Views } from '_types';
import _ from 'lodash';
import { setAlertPanel } from 'features/StationView/AlertSlice';
import { getCrossFlowData } from 'features/StationView/AnalysisSlice';
import { getMaxValueFromArray, getMinValueFromArray } from 'components/util';

const params = {
    envMapUrl: '/content/potsdamer_platz_2k.hdr',
};

export class VizStore {
    public visualization: Visualization;
    private static instance: VizStore;
    private currentCameraView?: string;
    public static onSetInstance?: () => void;

    private constructor(viz: Visualization) {
        this.visualization = viz;
        VizStore.instance = this;

        VizStore.setView();
        store.dispatch(setAlertPanel());
    }

    public static setInstance(viz) {
        VizStore.instance = new VizStore(viz);
    }

    public static getInstance(): VizStore {
        return VizStore.instance;
    }

    public static getCurrentCameraView(): string | undefined {
        return VizStore.instance.currentCameraView;
    }

    public static async setView(viewKey?: string) {
        if (viewKey) VizStore.instance.currentCameraView = viewKey;
        if (!this.instance || !VizStore.instance.currentCameraView) return;

        this.instance.visualization.camera.api.SetViewPoint(VizStore.instance.currentCameraView);
    }

    public static updateCameraView() {
        if (!VizStore.instance.currentCameraView) return;
        this.instance.visualization.camera.api.RefreshViewPoint(
            VizStore.instance.currentCameraView
        );
    }

    public static setAlertsPanels(alertsPanels: Record<string, AlertsPanel>) {
        if (!this.instance) return;

        this.instance.visualization.alerts.api.AddPanels(alertsPanels);
    }
}

interface Props {
    stationID?: string;
    analysis?: boolean;
}

const Visualizer: React.FC<Props> = (props) => {
    const { stationID, analysis = false } = props;
    if (!stationID)
        return <div>{"ERROR: VISUALIZER COMPONENT DIDN'T GET A STATION ID FOR THIS PAGE"}</div>;

    const dispatch = useAppDispatch();
    const crossFlowData = useAppSelector(getCrossFlowData);

    const wrapperRef = React.createRef<HTMLDivElement>();

    const urlsQuery: DigiTwinUIAPIQueryHookResult<{ assets: string[] }> = useGetDigiTwinUIAPIQuery(
        'stations/' + stationID + '/3dassets'
    );
    const viewsQuery: DigiTwinUIAPIQueryHookResult<CameraViewsQuery> = useGetDigiTwinUIAPIQuery(
        'stations/' + stationID + (analysis ? '/analysisviews' : '/cameraviews')
    );
    const equipmentsQuery = useGetDigiTwinUIAPIQuery('stations/' + stationID + '/equipments');

    React.useEffect(() => {
        console.log('Initial UseEffect');
        const element = wrapperRef.current;

        if (element) {
            const vpt = new Viewport(element, element.offsetWidth, element.offsetHeight);
            const viz = new Visualization(vpt, params);

            VizStore.setInstance(viz);
        }
    }, []);

    // trigger only on analysis flow
    useEffect(() => {
        if (analysis) {
            const viz = VizStore.getInstance().visualization;
            viz.analysis.clearCrossFlowModels();

            if (crossFlowData?.length > 0) {
                const maxCount = getMaxValueFromArray(crossFlowData)
                const minCount = getMinValueFromArray(crossFlowData)

                crossFlowData?.forEach((data) => viz.analysis.LoadModel(data, minCount, maxCount));
            }
        }
    }, [crossFlowData]);

    React.useEffect(() => {
        if (!urlsQuery.isSuccess || !viewsQuery.isSuccess || !equipmentsQuery.isSuccess) return;

        const viz = VizStore.getInstance().visualization;
        const urls = urlsQuery.data?.assets as string[];
        const viewsSelector = analysis ? 'analysis_views' : 'camera_views';
        const viewQueryResults = viewsQuery.data?.[viewsSelector];
        const views: Views = new Map(
            viewQueryResults.map((viewResult) => {
                const value: View = _.omit({ ...viewResult }, 'identifier');
                return [viewResult.identifier, value];
            })
        );

        dispatch(setCameraViews(views ?? new Map()));

        Promise.any(
            urls.map((url) =>
                viz.static.api.LoadModel(url, equipmentsQuery?.data.equipments.trains).then(() => {
                    VizStore.updateCameraView();
                })
            )
        ).then(() => {
            addViewPoints(views);
        });
    }, [urlsQuery, viewsQuery, equipmentsQuery]);

    window.addEventListener('resize', () => {
        if (wrapperRef.current) {
            VizStore.getInstance().visualization.vpt.setSize(
                wrapperRef.current.offsetWidth,
                wrapperRef.current.offsetHeight
            );
        }
    });

    return <div id="visualizer" ref={wrapperRef} />;
};

export default Visualizer;

const addViewPoints = (views: Views) => {
    Array.from(views?.entries() ?? []).forEach(([key, v]) => {
        VizStore.getInstance().visualization.camera.api.AddViewPoint(key, v);

        if (v.default && !VizStore.getCurrentCameraView()) {
            store.dispatch(setCurrentCameraView({ key, label: v.label }));
        }
    });
};
