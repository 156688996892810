import React, { useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { useAppSelector } from 'app/hooks';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { selectUnreadAlertsList } from 'features/StationView/AlertSlice';
import { AlertsIcon } from 'components/AlertToaster/AlertToaster';
import AlertManager from 'dataManagers/alertsManager/AlertManager';
import { EquipmentAlertQuery } from '_types/queries';

const AlertsMenu: React.FC<{ open: boolean; label: string }> = ({ open, label }) => {
    return (
        <div className={label === 'Alerts' ? 'alerts-menu' : 'alerts-subMenu'}>
            <span className="trigger-label">
                <label>{label}</label>
            </span>

            <span style={{ transform: `rotate(${open ? 0 : 180}deg)` }}>
                <ChevronDown />
            </span>
        </div>
    );
};

/**
 * Alert container to show the alert
 * @param alertData - Alert data to be shown
 */
const AlertContainer: React.FC<{ alertData: EquipmentAlertQuery }> = ({ alertData }) => {

    const triggerModal = (alertData) => {
        AlertManager.getInstance().ThrowPopUpAlert(alertData);
    };

    return (
        <div className="live-alerts-container" onClick={() => triggerModal(alertData)}>
            <div className="alert-icon">
                <AlertsIcon severity={3} />
            </div>
            <div className="alert-title">{alertData?.title}</div>
        </div>
    );
};

/**
 * Alerts are catogrized and shown on the sidebar
 * @param category - Category of the alert
 * @param isAlertsPresent - Flag to check if alerts are present
 * @param alerts - List of specific alerts 
 */
const AlertsCategory: React.FC<{
    category: string;
    isAlertsPresent: boolean;
    alerts: EquipmentAlertQuery[];
}> = ({ category, isAlertsPresent, alerts }) => (
    <div style={{ marginTop: '20px' }}>
        <Collapsible
            open={isAlertsPresent}
            transitionTime={500}
            trigger={<AlertsMenu open={true} label={category} />}
            triggerWhenOpen={<AlertsMenu open={false} label={category} />}>
            {isAlertsPresent ? (
                <div
                    style={{
                        marginTop: '16px',
                        maxHeight: '150px', // Adjust height as needed
                        overflowY: 'auto', // Enables scrolling when content exceeds maxHeight
                        // border: '1px solid #ccc', // Optional: to make it visually distinct
                        // padding: '8px', // Optional: for better spacing
                        // borderRadius: '8px', // Optional: for rounded corners
                    }}>
                    {alerts?.map((alert) => {
                        return (
                            <AlertContainer
                                key={`${alert?.eq}_${alert?.timestamp}`}
                                alertData={alert}
                            />
                        );
                    })}
                </div>
            ) : (
                <div style={{ color: 'gray', fontStyle: 'italic', padding: '10px' }}>
                    No alerts available
                </div>
            )}
        </Collapsible>
    </div>
);

export const SideBarAlerts: React.FC = () => {
    const alerts: EquipmentAlertQuery[] = useAppSelector(selectUnreadAlertsList);
    
    useEffect(() => {
        console.log(alerts.length)
    }, [alerts]);

    const isAlertsPresent: boolean = alerts.length > 0;

    return (
        <Collapsible
            open={isAlertsPresent}
            trigger={<AlertsMenu open={true} label="Alerts" />}
            transitionTime={500}
            triggerWhenOpen={<AlertsMenu open={false} label={`  Alerts`} />}>
            <div style={{ paddingLeft: '10px' }}>
                <AlertsCategory
                    category="Equipment Alerts"
                    isAlertsPresent={isAlertsPresent}
                    alerts={alerts}
                />
                {/* TODO: By default, setting congestion alerts as false because we need to check 
                    how we need to differentiate between various types of alert. */}
                <AlertsCategory
                    category="Congestion Alerts"
                    isAlertsPresent={false}
                    alerts={alerts}
                />
            </div>
        </Collapsible>
    );
};
