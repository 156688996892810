import React from 'react';
import { toast, ToastContainerProps } from 'react-toastify';
import { Path } from 'wouter';
import { store } from 'app/store';
import { setCurrentCameraView, setMainView } from 'features/StationView/StationViewSlice';
import { apolloClient } from 'app/clients/apolloClient';
import {
    GETVIDEOMONITORINGALERTS,
    SUBSCRIBEALERTS,
    SUBSCRIBEEQUIPMENTALERTS,
    SETEQUIPMENTALERTSREAD,
    GETEQUIPMENTALERTS,
} from '_queries';
import { AlertToaster } from 'components/AlertToaster/AlertToaster';
import { EquipmentAlertQuery } from '_types/queries';
import {
    deleteAlertPanel,
    setAlertPanel,
    setAlerts,
    setAlertToRead
} from 'features/StationView/AlertSlice';
import { CameraView } from '_types';

type setLocationProps = (
    to: Path,
    options?: {
        replace?: boolean;
    }
) => void;

type AlertViewOptions = {
    view: CameraView;
};

class AlertManager {
    private static instance: AlertManager;
    setLocation: setLocationProps;

    constructor(setLocation: setLocationProps) {
        AlertManager.instance = this;
        this.setLocation = setLocation;
        
        this.ListAlerts();

        apolloClient
            .subscribe({
                query: SUBSCRIBEEQUIPMENTALERTS,
                variables: { site_id: '01JD7BTJ2GEEYZ41799M94KWR8' },
            })
            .subscribe({
                next: ({ data: { onEquipmentAlert: alert } }) => {
                    store.dispatch(setAlerts([alert]));
                },
                error: (e) => console.error(e),
            });


        // setInterval(() => AlertManager.getInstance().ThrowPopUpAlert(mockAlert2), 20000);
        // setTimeout(() => AlertManager.getInstance().ThrowPopUpAlert(mockAlert2), 2000);
    }

    public static getInstance(): AlertManager {
        return AlertManager.instance;
    }
    // TODO: Get site_id from url
    /**
     * Get all the escalator alerts from the server.
     */
    private ListAlerts() {
        apolloClient
            .watchQuery<{ getEquipmentAlerts: EquipmentAlertQuery[] }>({
                query: GETEQUIPMENTALERTS,
                variables: { site_id: '01JD7BTJ2GEEYZ41799M94KWR8' },
            })
            .subscribe({
                next: ({ data }) => {
                    store.dispatch(setAlerts(data.getEquipmentAlerts));
                },
                error: (e) => console.error(e),
            });
    }

    public ThrowPopUpAlert(alert: EquipmentAlertQuery, ToastProps: ToastContainerProps = {}) {
        toast.dismiss();
        const level = 4;
        toast(
            ({ closeToast }) => {
                return (
                    <AlertToaster
                        severity={3}
                        title={alert.title}
                        message={alert.message}
                        closeToast={() => {
                            this.ActionsOnAlert(alert);
                            if (closeToast) closeToast();
                        }}
                        alertOptions={{
                            timestamp: alert.timestamp,
                            site: alert.site_id,
                        }}
                        onInvestigate={
                            alert.site_id
                                ? () => {
                                      this.SetViewToAlertPanel(alert, { view: alert.view });
                                      if (closeToast) closeToast();
                                  }
                                : undefined
                        }></AlertToaster>
                );
            },
            {
                autoClose: AlertWarningLevelMap[level].expireTimeMs as number | false | undefined,
                className: 'alert-container',
                bodyClassName: 'alert-body',
                progressClassName: 'alert-progress-bar',
                progressStyle: { background: '#fff1ec' },
                icon: null,
                position: 'bottom-left',
                style: { marginLeft: 'calc(var(--sidebar-width) + 8rem)' },
                ...ToastProps,
            }
        );

        // if (alert.panelPosition) {
        store.dispatch(
            setAlertPanel({
                position: { x: 162, y: -5.2, z: -159 },
                content: alert.message,
                severity: 3,
                id: alert.timestamp,
            })
        );
        // // }
    }

    /**
     * Set status of alert to read
     */
    public async ActionsOnAlert(alert: EquipmentAlertQuery, flow?: string) {
        try {
            const isInvestigateFlow = flow === 'investigateFlow';

            // Set read_status from "unread" t0 "read"
            const { data } = await apolloClient.mutate({
                mutation: SETEQUIPMENTALERTSREAD,
                variables: { eq: alert.eq, timestamp: alert.timestamp },
            });

            const { timestamp } = data?.updateReadStatusEquipmentChange || {};
            
            if (timestamp) {    
                store.dispatch(setAlertToRead(timestamp));

                if (!isInvestigateFlow) store.dispatch(deleteAlertPanel(timestamp));

                console.log('Alert Read Status Updated', data);
            }
        } catch (e) {
            console.error('Error Updating Read Status', e);
        }

    }

    public SetViewToAlertPanel(alert: EquipmentAlertQuery, options?: AlertViewOptions) {
        this.setLocation(`/stations/${alert.site_id}`);

        store.dispatch(setMainView('live'));

        // if (options?.view) store.dispatch(setCurrentCameraView({key: "camera_01JD7BWCQKSMSGTCDGFA7CDVB1", label: "Main escalators"}));
        store.dispatch(
            setCurrentCameraView({
                key: 'camera_01JD7BWCQKSMSGTCDGFA7CDVB1',
                label: 'Main escalators',
            })
        );

        this.ActionsOnAlert(alert, 'investigateFlow');
    }
}

export default AlertManager;

export const AlertWarningLevelMap = {
    1: { color: '#5df542', expireTimeMs: 5000 },
    2: { color: '#e1f719', expireTimeMs: 5000 },
    3: { color: '#f7bc19', expireTimeMs: 10000 },
    4: { color: '#ff5f28', expireTimeMs: false },
};

// type MockAlertType = AlertsQuery & {
//     panelPosition: { x: string; y: string; z: string };
// };

// export const mockAlert: MockAlertType = {
//     time_stamp: '1724739663',
//     version: '1',
//     message: 'alarm',
//     site_id: 'Rautatientori',
//     equipment_id: 'esc_1',
//     genai_message: 'Person falling down the escalator',
//     camera_id: 'Vsm232',
//     alarm_type: 'out of handrail',
//     alarm_status: 'incident start',
//     alarm_id: '101719884889',
//     read_status: 0,
//     panelPosition: { x: '162', y: '-5.2', z: '-159' },
// };

export const mockAlert2: EquipmentAlertQuery = {
    alert_type: 'ESCALATOR DOWN',
    direction: 'nan',
    eq: 'AA02',
    message: 'No one has used eq: AA02 for more than 5mins.',
    read_status: 'unread',
    site_id: '01JD7BTJ2GEEYZ41799M94KWR8',
    status: 'unread',
    timestamp: '1732615342',
    title: 'AA02 MAYBE NOT RUNNING',
    ttl_time: '1732709691',
    view: { key: 'camera_01JD7BWCQKSMSGTCDGFA7CDVB1', label: 'Main escalators' },
};
