import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GETPEOPLEONPLATFORMTREND } from '_queries';
import { GetPeopleOnPlatform, PeopleOnPlatformCount } from '_types';
import LineGraph from 'components/Graphs/LineGraph';
import { customTooltip, formatTimestamp } from 'components/util';
import { useRoute } from 'wouter';
import { calculateDataZoomStart } from 'components/Graphs/graphUtils';

const PeopleOnPlatformChart: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [peopleCountData, setPeopleCountData] = useState<GetPeopleOnPlatform>([]);
    const { data, loading, error } = useQuery<PeopleOnPlatformCount>(GETPEOPLEONPLATFORMTREND, {
        pollInterval: 60000,
        variables: { site_id: stationID },
    });
    const { getPeopleOnPlatformTrend } = data || {};

    const dataZoomStart = calculateDataZoomStart(peopleCountData, 9);

    useEffect(() => {
        if (!getPeopleOnPlatformTrend) return;

        setPeopleCountData(getPeopleOnPlatformTrend);
    }, [getPeopleOnPlatformTrend]);

    const transformData = peopleCountData?.map((item) => ({
        timestamp: formatTimestamp(item?.timestamp),
        plat_count: item.plat_count,
    })) as GetPeopleOnPlatform;

    return (
        <LineGraph
            title="People on Platform"
            data={transformData || []}
            dimensions={['timestamp', 'plat_count']}
            tooltipOption={{
                trigger: 'axis',
                formatter: (params) => {
                    return customTooltip(params, params[0]?.name);
                },
            }}
            xAxisOptions={{
                type: 'category',
                boundaryGap: false,
                axisTick: {
                    show: true,
                    alignWithLabel: true,
                },
            }}
            graphOptions={{
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        xAxisIndex: [0],
                        start: dataZoomStart,
                        end: 100,
                    },
                    {
                        type: 'inside',
                        xAxisIndex: [0],
                    },
                ],
            }}
            loading={loading}
            error={error}
        />
    );
};

export default PeopleOnPlatformChart;
