import FloorNavigator from 'components/FloorNavigator';
import Visualizer from 'components/Visualizer/Visualizer';
import React, { useEffect, useState } from 'react';
import Select, { SelectValue } from 'components/UIComponents/Select/Select';
import { useAppDispatch } from 'app/hooks';
import { setCrossFlowData } from 'features/StationView/AnalysisSlice';
import ReactEcharts from 'echarts-for-react';
import { getCrossFlowCountperHour, getUniqueDates } from 'components/util';
import { crossFlowHoursArray } from 'constants/constants';
import { CrossFlowAnalysis, CrossFlowData, CrossFlowGraphData } from '_types/analysis';
import { useRoute } from 'wouter';
import { useQuery } from '@apollo/client';
import { CROSSFLOWANALYSIS } from '_queries/analysis_queries';
import LoadingPage from 'components/LoadingPage/LoadingPage';

const AnalysisView: React.FC = () => {
    const dispatch = useAppDispatch();

    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const [datesList, setDatesList] = useState<SelectValue<string>[]>();
    const [selectedHour, setSelectedHour] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState<SelectValue<string>>();
    const [dataForCurrentDate, setDataForCurrentDate] = useState<CrossFlowData[]>([]);

    const crossFlowData: CrossFlowGraphData = getCrossFlowCountperHour(dataForCurrentDate);

    const { data, loading } = useQuery<CrossFlowAnalysis>(CROSSFLOWANALYSIS, {
        variables: { site_id: stationID },
    });

    const { getCrossFlowAnalysis } = data || {};

    useEffect(() => {
        if (getCrossFlowAnalysis) {
            // fetch all unique dates from given data
            const uniqueDates = getUniqueDates(getCrossFlowAnalysis);

            setDatesList(uniqueDates);
            setSelectedDate(uniqueDates[0]);
        }
    }, [getCrossFlowAnalysis]);

    useEffect(() => {
        if (!getCrossFlowAnalysis) return;

        // filter data for selected date
        const filterDataAccordingToDate = getCrossFlowAnalysis?.filter(
            (item) => item?.datetime?.split(' ')[0] === selectedDate?.value
        );

        // if date is changed, by default select hour as 0 to hightligt all data
        setSelectedHour(0);
        setDataForCurrentDate(filterDataAccordingToDate);
    }, [selectedDate, getCrossFlowAnalysis]);

    useEffect(() => {
        if (!dataForCurrentDate) return;

        const filteredData = selectedHour
            ? dataForCurrentDate?.filter((item) => item.event_hour === selectedHour)
            : dataForCurrentDate;
        dispatch(setCrossFlowData(filteredData));
    }, [dataForCurrentDate, selectedHour]);

    const onEvents = {
        'click': (params: Record<string, string>) => {
            setSelectedHour(parseInt(params?.name));
        },
    };

    const chartOptions = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true,
            },
            axisLine: {
                show: true,
            },
            splitLine: {
                show: false,
            },
            data: crossFlowHoursArray,
        },
        yAxis: {
            type: 'value',
            axisLabel: false,
            splitLine: {
                show: false,
            },
        },
        dataset: {
            dimensions: ['event_hour', 'count'],
            source: crossFlowData,
        },
        series: [
            {
                type: 'bar',
                connectNulls: true,
                barMinHeight: 30,
            },
        ],
    };

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <>
            <FloorNavigator />
            <div className="analysis-date-selector">
                <Select
                    placeholder="Select"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e as SelectValue<string>)}
                    options={datesList}
                />
            </div>
            <Visualizer stationID={stationID} analysis={true} />
            <div className="cross-flow-chart">
                <ReactEcharts option={chartOptions} onEvents={onEvents} style={{ height: 200 }} />
            </div>
        </>
    );
};

export default AnalysisView;
