import React, { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import AnalysisView from './AnalysisView';
import './stationTwinAnalysis.scss'

const StationTwinAnalysis: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    return <AnalysisView />;
};

export default StationTwinAnalysis;
