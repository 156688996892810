import { useMutation, useQuery } from '@apollo/client';
import { SETEQUIPMENTALERTSREAD } from '_queries';
import { EquipmentAlertQuery } from '_types/queries';
import moment from 'moment';
import React from 'react';
import './notificationCenter.scss';
import { capitalize } from 'components/util';
import { useAppSelector } from 'app/hooks';
import { selectAlerts } from 'features/StationView/AlertSlice';
import AlertManager from 'dataManagers/alertsManager/AlertManager';

const NotificationCenter: React.FC = () => {
    const alerts = useAppSelector(selectAlerts);
    const [setAlertsRead] = useMutation(SETEQUIPMENTALERTSREAD);

    React.useEffect(() => {
        alerts.forEach((alert) => {
            if (alert.read_status === "read") return;
            setAlertsRead({ variables: { eq: alert.eq, timestamp: alert.timestamp } });
        });
    }, [alerts]);

    return (
        <div className="notification-center">
            <span>Notification center</span>
            {[...alerts]
                .sort((a, b) => a && b && +b.timestamp - +a.timestamp)
                .map((alert) => (
                    <Message alert={alert} key={alert.title} />
                ))}
        </div>
    );
};

export default NotificationCenter;

export const Message: React.FC<{ alert: EquipmentAlertQuery }> = ({ alert }) => {
    const isToday =
        new Date(+alert.timestamp).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
    const timeFormat = isToday ? 'HH:mm:ss' : 'ddd MMM Do YYYY, HH:mm:ss';
    const date = moment.unix(Number(alert.timestamp));

    const onClick = () => {
        AlertManager.getInstance().SetViewToAlertPanel(alert);
    };

    return (
        <div onClick={onClick} className="notification">
            <div className="notification-first-section">
                <div className="notification-head">{`${date.format(
                    'dddd MMMM Do YYYY'
                )} - ${capitalize(alert.status)}`}</div>
                <div className="notification-body">{alert.message}</div>
                <div className="notification-footer">{capitalize(alert.site_id)}</div>
            </div>

            <div className="notification-second-section">
                <div className="notification-timestamp">{date.format(timeFormat)}</div>
            </div>
        </div>
    );
};
