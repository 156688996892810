import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as THREE from 'three';
import { CameraView, Views } from '_types';
import { VizStore } from 'components/Visualizer/Visualizer';

import { RootState } from '../../app/store';

export type MainViewOptions = 'live' | 'dashboard' | 'analysis' | 'virtual operator';

export interface StationViewState {
    mainSidebarFull: boolean;
    mainView: MainViewOptions;

    cameraViews: Views;
    currentCameraView: CameraView;
    status: 'idle' | 'pending' | 'failed';
    eye: THREE.Vector3;
    target: THREE.Vector3;
}

const initialState: StationViewState = {
    mainSidebarFull: true,
    mainView: 'live',

    cameraViews: new Map<
        string,
        { label: string; camera: { eye: THREE.Vector3; target: THREE.Vector3 } }
    >(),
    currentCameraView: { label: 'Entrance', key: 'entrance' },
    status: 'idle',
    eye: new THREE.Vector3(),
    target: new THREE.Vector3(),
};

/*===============================
             Thunks
===============================*/

export const setCurrentCameraView = createAsyncThunk(
    'StationView/setCurrentCameraView',
    async (view: CameraView) => {
        VizStore.setView(view.key);
        return view;
    }
);

/*===============================
              Slice
===============================*/

export const StationViewSlice = createSlice({
    name: 'StationView',
    initialState,
    reducers: {
        setMainSidebarFull: (state, action: PayloadAction<boolean>) => {
            state.mainSidebarFull = action.payload;
        },
        setMainView: (state, action: PayloadAction<MainViewOptions>) => {
            state.mainView = action.payload;
            localStorage.setItem('mainView', action.payload);
        },

        setCameraViews: (state, action: PayloadAction<Views>) => {
            state.cameraViews = action.payload;
        },
        //Only for dev
        setEyeTarget: (state, action: PayloadAction<{ eye: THREE.Vector3; target: THREE.Vector3 }>) => {
            state.eye = action.payload.eye;
            state.target = action.payload.target;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setCurrentCameraView.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(setCurrentCameraView.fulfilled, (state, action) => {
                state.status = 'idle';
                state.currentCameraView = action.payload;
            })
            .addCase(setCurrentCameraView.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

/*===============================
            Actions
===============================*/

export const {
    setMainSidebarFull,
    setMainView,
    setEyeTarget, // only for dev
    setCameraViews,
} = StationViewSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectMainSidebarFull = (state: RootState) => state.stationView.mainSidebarFull;
export const selectMainView = (state: RootState) => state.stationView.mainView;

export const selectStationView = (state: RootState) => state.stationView;
export const selectCurrentCameraView = (state: RootState) => state.stationView.currentCameraView;
export const selectCameraViews = (state: RootState) => state.stationView.cameraViews;


// Only for de
export const selectEye = (state: RootState) => state.stationView.eye;
export const selectTarget = (state: RootState) => state.stationView.target;

export default StationViewSlice.reducer;
