import React from 'react';
import './GlobalErrorPage.scss';
import { Button } from 'components/UIComponents/Button/Button';
import { useAppDispatch } from 'app/hooks';
import { logoutThunk } from 'features/auth/AuthSlice';
import { TopNavbar } from 'components/TopNavbar/TopNavbar';

const ErrorType = {
    404: 'Page Not Found',
    500: 'Internal Server Error',
    403: 'You are not authorized to view this page',
};

export const GlobalErrorPage: React.FC = () => {
    
    const statusCode = sessionStorage.getItem('apiErrorStatus') || 'unknown';

    const dispatch = useAppDispatch();
    const logout = () => {
        dispatch(logoutThunk());
    };

    return (
        <>
        <TopNavbar />
        <div className="error-page">
            <h1 className="error-page__title">{statusCode}</h1>
            <h2 className="error-page__message">{ErrorType[statusCode]}</h2>
            <Button className="logout-button" onClick={() => logout()}>
                Logout
            </Button>
        </div>
        </>
    );
};
